

























































import '@/components/Vue2Leaflet';
import { Component, Prop, Vue, Watch, Mixins } from 'vue-property-decorator';
import pupa from 'pupa';
import * as L from 'leaflet';
import config from '@/config';
import { mod } from '@/utils';

@Component({})
export default class DashboardComponentMap extends Vue {
  @Prop() public component!: any;
  @Prop() public variables!: any;
  @Prop() public options!: any;
  @Prop() public data!: any;
  @Prop() public embed!: boolean;

  public levelsWithImages: any = [];
  private mapCRS: any = L.CRS.Simple;
  private currentLevelId: any = null;
  private mapOptions: any = {
    zoomSnap: 0,
    zoomControl: false,
  };
  private mapGeoJson: any = null;
  private ro: any = null;
  private sliderCycleInterval: any = null;

  private mounted() {
    this.ro = new ResizeObserver(this.onResize);
    this.ro.observe(this.$refs.lmapContainer);
    this.refreshFromVariables();
  }

  private beforeDestroy() {
    this.unloadSliderCycle();
    if (this.ro) {
      this.ro.unobserve(this.$refs.lmapContainer);
      this.ro = null;
    }
  }

  private renderTemplate(text: any) {
    return pupa(text, this.variables);
  }

  private unloadSliderCycle() {
    if (this.sliderCycleInterval !== null) {
      clearTimeout(this.sliderCycleInterval);
      this.sliderCycleInterval = null;
    }
  }

  private refreshSliderCycle() {
    // we call it refresh as if a user action is done, the interval is resetted.
    this.unloadSliderCycle();
    const interval: any = (this.options.slider ? this.options.slider.interval : null);
    if (interval !== null && interval > 0) {
      this.sliderCycleInterval = setTimeout(() => {
        this.gotoNextLevel();
      }, interval * 1000);
    }
  }

  private onResize() {
    const obj: any = this.$refs.lmap;
    if (obj && obj.mapObject) {
      obj.mapObject.invalidateSize();
      this.refreshLevelFitReal();
    }
  }

  @Watch('variables')
  private refreshFromVariables() {
    const variables = this.variables;
    const building = this.variables.building;
    if (!building || !building.levels) {
      this.levelsWithImages = [];
    } else {
      this.currentLevelId = null;
      this.loadLevelsWithImages();
    }
    this.refreshSliderCycle();
  }

  private loadLevelsWithImages() {
    const variables = this.variables;
    const building = this.variables.building;

    const levels: any[] = [];
    for (const level of building.levels) {
      if (level.image !== null) {
        levels.push(level);
        if (this.currentLevelId === null) {
          this.currentLevelId = level.id;
        }
      }
    }
    this.levelsWithImages = levels;
    this.refreshGeoJson();
    this.refreshLevelFit();
  }

  @Watch('currentLevelId')
  @Watch('levelsWithImages')
  private refreshLevelFit() {
    if (!this.currentLevelId || this.levelsWithImages.length === 0) {
      return;
    }
    if (!this.$refs.lmap) {
      if (this.levelsWithImages.length > 0) {
        Vue.nextTick(() => {
          this.refreshLevelFitReal();
        });
      }
      return;
    }
    // Vue.nextTick(() => {
    //   this.refreshLevelFitReal();
    // });
  }

  private refreshLevelFitReal() {
    if (this.levelsWithImages.length === 0) {
      return;
    }
    const lmap: any = this.$refs.lmap;
    if (typeof lmap === 'undefined') {
      return;
    }
    lmap.mapObject.fitBounds(
      this.currentLevelImageBounds, {
        animate: false,
      });
  }

  private gotoPreviousLevel() {
    let idx = this.levelsWithImages.findIndex(
      (el: any) => el.id === this.currentLevelId);
    if (idx === -1) {
      return;
    }
    idx = mod(idx - 1, this.levelsWithImages.length);
    this.currentLevelId = this.levelsWithImages[idx].id;
    this.refreshSliderCycle();
    Vue.nextTick(() => {
      this.refreshLevelFitReal();
    });
  }

  private gotoNextLevel() {
    let idx = this.levelsWithImages.findIndex(
      (el: any) => el.id === this.currentLevelId);
    if (idx === -1) {
      return;
    }
    idx = mod(idx + 1, this.levelsWithImages.length);
    this.currentLevelId = this.levelsWithImages[idx].id;
    this.refreshSliderCycle();
    Vue.nextTick(() => {
      this.refreshLevelFitReal();
    });
  }

  private actionZoomIn() {
    const lmap: any = this.$refs.lmap;
    if (lmap !== undefined) {
      lmap.mapObject.zoomIn();
      this.refreshSliderCycle();
    }
  }

  private actionZoomOut() {
    const lmap: any = this.$refs.lmap;
    if (lmap !== undefined) {
      lmap.mapObject.zoomOut();
      this.refreshSliderCycle();
    }
  }

  @Watch('data')
  @Watch('currentLevelId')
  private refreshGeoJson() {
    if (!this.currentLevel || !this.currentLevel.geojson) {
      this.mapGeoJson = null;
      return;
    }
    const geojson: any = this.currentLevel.geojson;
    this.mapGeoJson = {
      type: geojson.type,
      features: geojson.features.filter((feature: any) =>
        (
          feature.type === 'Feature' &&
          feature.geometry.type === 'Polygon' &&
          feature.properties.areaId !== undefined &&
          this.$store.getters.getAreaFromId(feature.properties.areaId).analyzer
        ),
      ).map((feature: any) => {
        return feature;
      }),
    };
  }

  private styleGeoJson(feature) {
    const areaId = feature.properties.areaId;
    let value = null;
    if (this.data && this.data.values) {
      value = this.data.values[areaId];
    }
    return {
      fillColor: (value !== null && this.options.mapping.color[value]) || '#f0f0f0',
      color: (value !== null && this.options.mapping.color[value]) || '#f0f0f0',
      weight: 2,
      opacity: 1,
      fillOpacity: .5,
    };
  }

  get currentLevel() {
    if (this.levelsWithImages.length === 0) {
      return;
    }
    for (const level of this.levelsWithImages) {
      if (level.id === this.currentLevelId) {
        return level;
      }
    }
  }

  get currentLevelImageUrl() {
    if (!this.currentLevel) {
      return null;
    }
    return `${config.api_url}/organisation/building/${this.variables.building.id}/level/${this.currentLevel.id}/image`;
  }

  get currentLevelImageBounds() {
    if (!this.currentLevel) {
      return [[0, 0], [10, 10]];
    }
    return [
      [0, 0],
      [
        this.currentLevel.image.size[1],
        this.currentLevel.image.size[0],
      ],
    ];
  }
}
