








































































import { Component, Prop, Vue, Watch, Mixins } from 'vue-property-decorator';
import DashboardComponent from './DashboardComponent.vue';
import VueGridLayout from 'vue-grid-layout';
import { mod } from '@/utils';

@Component({
  components: {
    DashboardComponent,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
  },
})
export default class DashboardRenderer extends Vue {
  @Prop({default: () => { return {
      title: '',
      variables: {},
      required: [],
      template: [],
    };
  }}) public template: any;
  @Prop({default: false}) public hideTitle!: boolean;

  private variables: any = {};
  private duration: any = '24h';
  private templateComponents: any = [];
  private elem: any = null;
  private buildingId: any = null;
  private areas: any = [];

  public created() {
      this.loadTemplate();
  }

  public mounted() {
    this.elem = document.documentElement;
  }

  @Watch('template', { deep: true })
  private loadTemplate() {
    const variables = {...this.template.variables};
    this.templateComponents = {...this.template.template};

    if (variables.building_id) {
      variables.building = this.$store.getters.getBuildingFromId(
        variables.building_id);
      this.buildingId = variables.building_id;
    }

    this.loadAreas();

    if (variables.area_id) {
      variables.area = this.$store.getters.getAreaFromId(
        variables.area_id);
    } else if (this.areas.length > 0) {
      variables.area_id = this.areas[0].id;
      variables.area = this.areas[0];
    }

    this.$set(this, 'variables', variables);
  }

  private loadAreas() {
    const building = this.$store.getters.getBuildingFromId(this.buildingId);

    if (!this.buildingId) {
      return;
    }

    const areas: any[] = [];
    let areaId: any = null;
    for (const level of building.levels) {
      for (const area of level.areas) {
        if (!area.analyzer) {
          continue;
        }
        areas.push(area);
        if (areaId === null) {
          areaId = area.id;
          const variables = {...this.variables};
          variables.area_id = area.id;
          variables.area = area;
          this.variables = variables;
        }
      }
    }
    this.areas = areas;
  }

  private toggleFullscreen() {
    const doc: any = document;
    if (
        doc.fullscreenElement ||
        doc.webkitFullscreenElement ||
        doc.webkitCurrentFullscreenElement ||
        doc.mozFullScreenElement ||
        doc.msFullscreenElement) {
      this.closeFullscreen();
    } else {
      this.openFullscreen();
    }
  }

  private openFullscreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.webkitRequestFullscreen) { /* Safari */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) { /* IE11 */
      this.elem.msRequestFullscreen();
    }
  }

  private closeFullscreen() {
    const doc: any = document;
    if (doc.exitFullscreen) {
      doc.exitFullscreen();
    } else if (doc.webkitExitFullscreen) { /* Safari */
      doc.webkitExitFullscreen();
    } else if (doc.msExitFullscreen) { /* IE11 */
      doc.msExitFullscreen();
    }
  }

  @Watch('buildingId')
  private onBuildingId(value: number) {
    this.$emit('building-changed', value);
  }

  private onTemplateCommand(command: any) {
    if (command === 'AREA:PREV') {
      const idx = this.areas.findIndex(
        (entry: any) => entry.id === this.variables.area_id);
      const area = this.areas[mod(idx - 1, this.areas.length)];
      this.setArea(area);
    } else if (command === 'AREA:NEXT') {
      const idx = this.areas.findIndex(
        (entry: any) => entry.id === this.variables.area_id);
      const area = this.areas[mod(idx + 1, this.areas.length)];
      this.setArea(area);
    }
  }

  private setArea(area: any) {
    const variables = {...this.variables};
    variables.area_id = area.id;
    variables.area = area;
    this.variables = variables;
  }

  get getLayout() {
    return this.template['template']['layout'] || null;
  }

}
