import { render, staticRenderFns } from "./DashboardEditorComponentTip.vue?vue&type=template&id=77b453dc&scoped=true&"
import script from "./DashboardEditorComponentTip.vue?vue&type=script&lang=ts&"
export * from "./DashboardEditorComponentTip.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77b453dc",
  null
  
)

export default component.exports
import QInput from 'quasar/src/components/input/QInput.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QInput,QToggle});
