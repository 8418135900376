var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"q-pa-md"},[_c('div',{staticClass:"row q-col-gutter-md"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"row"},[_c('q-select',{attrs:{"label":"Indicateur de pièce","options":_vm.selectIndicatorOptions,"emit-value":"","map-options":"","dense":""},model:{value:(_vm.indicator),callback:function ($$v) {_vm.indicator=$$v},expression:"indicator"}})],1),_c('div',{staticStyle:{"white-space":"pre-line"}},[_vm._v(" "+_vm._s(_vm.getIndicatorHelp(_vm.indicator))+" ")]),_c('div',{staticClass:"row"},[_c('q-input',{attrs:{"label":"Titre de la carte","dense":""},model:{value:(_vm.options_title_text),callback:function ($$v) {_vm.options_title_text=$$v},expression:"options_title_text"}})],1),_c('div',{staticClass:"row"},[_c('q-input',{attrs:{"label":"Sous-titre de la carte","dense":""},model:{value:(_vm.options_subtitle_text),callback:function ($$v) {_vm.options_subtitle_text=$$v},expression:"options_subtitle_text"}})],1),_c('div',{staticClass:"row"},[_c('q-input',{attrs:{"label":"Unité","dense":""},model:{value:(_vm.options_unit_text),callback:function ($$v) {_vm.options_unit_text=$$v},expression:"options_unit_text"}})],1)]),_c('div',{staticClass:"col-3"},[_c('div',{staticClass:"option-section row items-center"},[_c('div',{staticClass:"option-title col-2 col-xs-12"},[_vm._v("Couleurs")]),_c('div',{staticClass:"option-content col-8 col-xs-12 flex column"},[_c('IndicatorValueMapper',{attrs:{"mapperType":"color"},model:{value:(_vm.options_mapping_color),callback:function ($$v) {_vm.options_mapping_color=$$v},expression:"options_mapping_color"}})],1)]),_c('div',{staticClass:"option-section row items-center"},[_c('div',{staticClass:"option-title col-2 col-xs-12"},[_vm._v("Défilement des étages")]),_c('div',{staticClass:"option-content col-8 col-xs-12 flex column"},[_c('q-select',{attrs:{"label":"Interval du défilement","options":[
                            {label: 'Désactivé', value: 0},
                            {label: '5 secondes', value: 5},
                            {label: '10 secondes', value: 10},
                            {label: '20 secondes', value: 20},
                            {label: '30 secondes', value: 30},
                            {label: '1 minute', value: 60},
                            {label: '2 minutes', value: 120},
                            {label: '5 minutes', value: 300} ],"emit-value":"","map-options":"","dense":""},model:{value:(_vm.options_slider_interval),callback:function ($$v) {_vm.options_slider_interval=$$v},expression:"options_slider_interval"}})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }