import { render, staticRenderFns } from "./DashboardEditorComponent.vue?vue&type=template&id=2d581cb1&scoped=true&"
import script from "./DashboardEditorComponent.vue?vue&type=script&lang=ts&"
export * from "./DashboardEditorComponent.vue?vue&type=script&lang=ts&"
import style0 from "./DashboardEditorComponent.vue?vue&type=style&index=0&id=2d581cb1&lang=scss&scoped=true&"
import style1 from "./DashboardEditorComponent.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d581cb1",
  null
  
)

export default component.exports
import QSelect from 'quasar/src/components/select/QSelect.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSelect,QBtn,QDialog,QCard,QCardSection});qInstall(component, 'directives', {ClosePopup});
