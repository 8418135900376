


























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { apiGet } from '@/store';
import { debounce } from 'ts-debounce';
import DashboardComponentCard from './DashboardComponentCard.vue';
import DashboardComponentSection from './DashboardComponentSection.vue';
import DashboardComponentMap from './DashboardComponentMap.vue';
import DashboardComponentCounter from './DashboardComponentCounter.vue';
import DashboardComponentTip from './DashboardComponentTip.vue';
import pupa from 'pupa';
import merge from 'lodash/merge';
import '@/scss/dashboard.scss';

@Component({
  components: {
    DashboardComponentCard,
    DashboardComponentSection,
    DashboardComponentMap,
    DashboardComponentCounter,
    DashboardComponentTip,
  },
})
export default class DashboardComponent extends Vue {
  @Prop() public component!: any;
  @Prop() public variables!: any;
  @Prop() public duration!: any;
  @Prop() public embed!: boolean;
  public data = null;
  public error = null;

  private refreshForMap: any = null;
  private refreshForGeneric: any = null;
  private refreshForTip: any = null;
  private refreshIntervalId: any = null;

  private defaultOptions: any = {
    duration: '24h',
    interval: '1h',
    title: {
      disabled: false,
      text: '',
    },
    subtitle: {
      disabled: false,
      text: '',
    },
    icon: {
      'disabled': false,
      'use-mapping': false,
      'name': 'qai-good',
    },
    value: {
      'disabled': false,
      'use-mapping': false,
      'uppercase': true,
    },
    unit: {
      disabled: false,
      text: '',
    },
    textvalue: {
      disabled: false,
    },
    graph: {
      disabled: false,
      type: 'line',  // bar, line
    },
    mapping: {
      text: {
        0: 'Bon',
        1: 'Moyen',
        2: 'Mauvais',
      },
      color: {
        0: '#6FCFC9',
        1: '#FCBC42',
        2: '#FF8989',
      },
      icon: {
        0: 'qai-good',
        1: 'qai-bad',
        2: 'qai-bad',
      },
    },
  };
  private options: any = {};

  private created() {
    this.refreshForMap = debounce(this.refreshForMapReal, 10);
    this.refreshForGeneric = debounce(this.refreshForGenericReal, 10);
    this.refreshForTip = debounce(this.refreshForTipReal, 10);
    this.refreshOptions();
  }

  private mounted() {
    this.refreshIntervalId = setInterval(this.refresh, 60000);
  }

  private beforeDestroy() {
    if (this.refreshIntervalId !== null) {
      clearInterval(this.refreshIntervalId);
      this.refreshIntervalId = null;
    }
    this.refreshForMap.cancel();
    this.refreshForGeneric.cancel();
    this.refreshForTip.cancel();
  }

  @Watch('component', {deep: true})
  @Watch('duration')
  private refreshOptions() {
    const options = {};
    const currentOptions = {duration: this.duration};
    merge(options, this.defaultOptions, currentOptions, this.component.options);
    this.$set(this, 'options', options);
    this.refresh();
  }

  @Watch('variables', {deep: true})
  private refreshFromVariables(newVal: any, oldVal: any) {
    if (this.component.indicator) {
      if (this.component.indicator.context === 'area') {
        if (newVal.area_id !== oldVal.area_id) {
          this.refresh();
        }
      } else if (this.component.indicator.context === 'building') {
        if (newVal.building_id !== oldVal.building_id) {
          this.refresh();
        }
      }
    }
  }


  @Watch('options', {deep: true})
  @Watch('duration')
  private refresh() {
    if (this.component.type === 'tip') {
      this.refreshForTip();
    } else {
      if (!this.component.indicator) {
        return;
      }
      if (this.component.type === 'map') {
        this.refreshForMap();
      } else {
        this.refreshForGeneric();
      }
    }
  }

  private refreshForMapReal() {
    const indicatorId = pupa('{building.id}', this.variables);
    const indicatorName = pupa(this.component.indicator.name, this.variables);
    const url = `/indicators/building-areas/${indicatorId}/${indicatorName}`;

    apiGet(url).then((response: any) => {
      this.data = response;
      this.error = null;
    }).catch((response: any) => {
      this.data = null;
      this.error = response;
    });
  }

  private refreshForTipReal() {
    const indicatorId = pupa('{building.id}', this.variables);
    const url = `/building/${indicatorId}/tip`;
    apiGet(url).then((response: any) => {
      this.data = response;
      this.error = null;
    }).catch((response: any) => {
      this.data = null;
      this.error = response;
    });
  }

  private refreshForGenericReal() {
    const indicatorId = pupa(this.component.indicator.id, this.variables);
    const indicatorContext = pupa(this.component.indicator.context, this.variables);
    const indicatorName = pupa(this.component.indicator.name, this.variables);
    const url = `/indicators/${indicatorContext}/${indicatorId}/${indicatorName}`;

    let duration = this.options.duration;
    let interval = this.options.interval;
    if (this.duration === '7d') {
      duration = '7d';
      interval = '6h';
    } else if (this.duration === '24h') {
      duration = '24h';
      interval = '1h';
    }
    const params = { duration, interval };

    apiGet(url, params).then((response: any) => {
      this.data = response;
      this.error = null;
    }).catch((response: any) => {
      this.data = null;
      this.error = response;
    });
  }

  private onTemplateCommand(cmd: any) {
    this.$emit('template-command', cmd);
  }

}
