





































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import pupa from 'pupa';


@Component({})
export default class DashboardComponentCounter extends Vue {
  @Prop() public component!: object;
  @Prop() public variables!: object;
  @Prop() public options!: any;
  @Prop() public data!: any;
  @Prop() public error!: any;
  @Prop() public embed!: boolean;

  private renderTemplate(text: any) {
    return pupa(text, this.variables);
  }

  get status() {
    if (this.data === null) {
      if (this.error !== null) {
        return 'error';
      }
      return 'loading';
    } else {
      if (!this.data.values || this.data.values.length <= 0) {
        return 'nodata';
      }
    }
    return 'ok';
  }

  get cssComponentStatus() {
    return 'dc-component-status-' + this.status;
  }

  get errorMessage() {
    const component: any = this.component;
    const context = component.indicator.context;
    const variables: any = this.variables;
    if (context === 'building' && !variables.building_id) {
      return 'Aucun bâtiment sélectionné.';
    } else if (context === 'area' && !variables.area_id) {
      return 'Aucune pièce sélectionnée.';
    }
    return 'Erreur à la lecture des données.';
  }

  get cssComponentColor() {
    return 'inherit';
  }

}
