















































import { Component, Prop, Vue, Watch, VModel } from 'vue-property-decorator';
import IconSelector from './IconSelector.vue';
import IndicatorValueMapper from './IndicatorValueMapper.vue';
import DashboardEditorComponentBase from './DashboardEditorComponentBase';

@Component({
    components: {
        IconSelector,
        IndicatorValueMapper,
    },
})
export default class DashboardEditorComponentTip extends DashboardEditorComponentBase {
}
