var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('q-tabs',{staticClass:"text-default bg-grey-3",attrs:{"dense":"","active-color":"primary","indicator-color":"primary","align":"justify","narrow-indicator":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('q-tab',{attrs:{"name":"dashboardMetadata","label":"Méta données"}}),_c('q-tab',{attrs:{"name":"dashboardEdit","label":"Edition"}}),_c('q-tab',{attrs:{"name":"dashboardCode","label":"Code"}})],1),_c('q-tab-panels',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('q-tab-panel',{staticStyle:{"padding":"0px"},attrs:{"name":"dashboardMetadata"}},[_c('div',{staticClass:"row q-col-gutter-sm"},[_c('div',{staticClass:"col-xs-12 col-sm-4"},[_c('q-input',{attrs:{"label":"Titre du dashboard","lazy-rules":"","rules":[ function (val) { return val && val.length > 3 || 'Champ trop court'; } ]},model:{value:(_vm.dashboard.name),callback:function ($$v) {_vm.$set(_vm.dashboard, "name", $$v)},expression:"dashboard.name"}})],1),_c('div',{staticClass:"col-xs-12 col-sm-4"},[_c('q-select',{attrs:{"label":"Type du dashboard","options":[
                        {label: 'Aucun', value: null},
                        {label: 'Bâtiment', value: 'building'},
                        {label: 'Pièce', value: 'area'} ],"map-options":"","emit-value":""},model:{value:(_vm.dashboard.dashboard_type),callback:function ($$v) {_vm.$set(_vm.dashboard, "dashboard_type", $$v)},expression:"dashboard.dashboard_type"}})],1),_c('div',{staticClass:"col-xs-12 col-sm-4 flex"},[_c('q-toggle',{attrs:{"label":"Disponible publiquement"},model:{value:(_vm.dashboard.is_published),callback:function ($$v) {_vm.$set(_vm.dashboard, "is_published", $$v)},expression:"dashboard.is_published"}})],1)]),_c('div',{staticClass:"row"},[_c('q-input',{attrs:{"label":"Description publique","type":"textarea","lazy-rules":"","rules":[ function (val) { return val && val.length > 3 || 'Champ trop court'; } ]},model:{value:(_vm.dashboard.description),callback:function ($$v) {_vm.$set(_vm.dashboard, "description", $$v)},expression:"dashboard.description"}})],1),_c('br')]),_c('q-tab-panel',{staticStyle:{"padding":"0px"},attrs:{"name":"dashboardEdit"}},[_c('br'),_c('div',{staticClass:"row",staticStyle:{"margin-top":"-20px","margin-bottom":"20px"}},[_c('q-select',{staticClass:"col-6",attrs:{"label":"Bâtiment de test","options":_vm.$store.state.buildings,"option-value":"id","option-label":"name","emit-value":"","map-options":"","clearable":"","dense":""},model:{value:(_vm.buildingId),callback:function ($$v) {_vm.buildingId=$$v},expression:"buildingId"}}),_c('q-select',{staticClass:"col-6",attrs:{"label":"Pièce de test","options":_vm.$store.getters.getAreas(),"option-value":"id","option-label":function (entry) { return ((_vm.$store.getters.getBuildingFromId(entry.building_id).name) + " ᐳ " + (entry.name)); },"emit-value":"","map-options":"","clearable":"","dense":""},model:{value:(_vm.areaId),callback:function ($$v) {_vm.areaId=$$v},expression:"areaId"}})],1),_c('grid-layout',{attrs:{"layout":_vm.template['layout'],"cols":{
                md: 3,
                sm: 6,
                xs: 12,
            },"row-height":160,"is-draggable":true,"is-resizable":true,"responsive":false,"vertical-compact":true,"margin":[10, 10],"use-css-transforms":true},on:{"update:layout":function($event){return _vm.$set(_vm.template, 'layout', $event)}}},_vm._l((_vm.template['layout']),function(item){return _c('grid-item',{key:item.i,attrs:{"x":item.x,"y":item.y,"w":item.w,"h":item.h,"i":item.i}},[_c('DashboardEditorComponent',{attrs:{"variables":_vm.variables},on:{"delete-component":function($event){return _vm.deleteComponent(item.i)}},model:{value:(_vm.template['components'][item.i]),callback:function ($$v) {_vm.$set(_vm.template['components'], item.i, $$v)},expression:"template['components'][item.i]"}})],1)}),1),_c('q-btn',{attrs:{"outline":""},on:{"click":function($event){return _vm.addTemplateComponent()}}},[_vm._v("Ajouter un composant")])],1),_c('q-tab-panel',{attrs:{"name":"dashboardCode"}},[_c('pre',[_vm._v(_vm._s(_vm.content))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }