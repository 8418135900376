



































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { apiGet } from '../../store';
import axios from 'axios';
import config from '../../config';
import DashboardEditor from '@/components/dashboards/DashboardEditor.vue';
import cloneDeep from 'lodash/cloneDeep';

@Component({
  components: {
    DashboardEditor,
  },
})
export default class DashboardAddEdit extends Vue {
  @Prop() public id!: number;

  public $store: any;
  public $q: any;
  public $route: any;
  public loading: boolean = true;
  public dashboard: any = {
    name: '',
    content: {
      template: {
        rowHeight: 120,
        layout: [],
        components: {},
      },
    },
  };

  public async created() {
    this.refresh();
  }

  @Watch('$route')
  private refresh() {
    this.loading = true;
    if (this.id !== null) {
      apiGet(`/dashboard/${this.id}`)
      .then((response: any) => {
        this.dashboard = response;
        this.loading = false;
      }).catch((error: any) => {
        this.loading = false;
        this.globalError(error);
      });
    } else {
      this.loading = false;
    }
  }

  private submitForm() {
    const form = this.$refs.form as HTMLFormElement;
    form.submit();
  }

  private stripUuid(data: any) {
    if (Array.isArray(data)) {
      return data.map((entry) => this.stripUuid(entry));
    }
    if (typeof(data) === 'object' && data !== null) {
      return Object.keys(data)
        .filter((key) => key !== '__uuid')
        .reduce((obj, key) => {
          obj[key] = this.stripUuid(data[key]);
          return obj;
        }, {});
    }
    return data;
  }

  private onSubmit() {
    this.loading = true;

    const payload = {
      name: this.dashboard.name,
      content: this.stripUuid(cloneDeep(this.dashboard.content)),
      is_published: this.dashboard.is_published,
      description: this.dashboard.description,
      dashboard_type: this.dashboard.dashboard_type,
    };

    let promise;
    let method: string;
    if (this.id === null) {
      promise = axios.post(`${config.api_url}/admin/dashboards`, payload);
      method = 'ajouté';
    } else {
      promise = axios.patch(`${config.api_url}/dashboard/${this.id}`, payload);
      method = 'mis à jour';
    }

    promise.then((response: any) => {
      const data = response.data;
      this.$q.notify({
          message: `${data.name} a été ${method}`,
          color: 'teal',
        });
      this.$router.push(`/admin/dashboard/${data.id}/edit`);
      this.dashboard = data;
      this.loading = false;
    }).catch((error: any) => {
      this.loading = false;
      this.globalError(error);
    });
  }
}
