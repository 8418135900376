




























































































































import { Component, Vue, VModel } from 'vue-property-decorator';
import DashboardEditorComponent from './DashboardEditorComponent.vue';
import DashboardRenderer from './DashboardRenderer.vue';
import cloneDeep from 'lodash/cloneDeep';
import VueGridLayout from 'vue-grid-layout';
import { v4 as uuidv4 } from 'uuid';

@Component({
    components: {
        DashboardEditorComponent,
        DashboardRenderer,
        GridLayout: VueGridLayout.GridLayout,
        GridItem: VueGridLayout.GridItem,
    },
})
export default class DashboardEditor extends Vue {
    @VModel({ type: Object }) public dashboard!: any;
    private buildingId: any = null;
    private areaId: any = null;
    private tab: any = 'dashboardMetadata';

    private mounted() {
        if (this.$store.state.buildings.length > 0) {
            const building = this.buildingId = this.$store.state.buildings[0];
            this.buildingId = building.id;
            const areas = this.$store.getters.getAreasFromBuilding(building);
            this.areaId = areas[0].id;
        }
    }

    get content() {
        return this.dashboard.content;
    }

    get template() {
        return this.content['template'];
    }

    private addTemplateComponent() {
        const uid = uuidv4();
        this.$set(this.template['components'], uid, {
            type: null,
            options: {},
        });
        this.template['layout'].push({
            x: 0,
            y: 0,
            w: 4,
            h: 2,
            i: uid,
        });
    }

    private deleteComponent(uid: string) {
        delete this.template['components'][uid];
        this.template['layout'].forEach((item: any, index: any) => {
            if (item.i === uid) {
                this.template['layout'].splice(index, 1);
            }
        });
    }

    get content_with_variables() {
        const output = cloneDeep(this.content);
        output['variables'] = {
            building_id: this.buildingId,
            area_id: this.areaId,
        };
        return output;
    }

    get variables() {
        const variables = {
            building_id: this.buildingId,
            area_id: this.areaId,
            building: null,
            area: null,
        };
        if (variables.building_id) {
            variables.building = this.$store.getters.getBuildingFromId(variables.building_id);
        }
        if (variables.area_id) {
            variables.area = this.$store.getters.getAreaFromId(variables.area_id);
        }
        return variables;
    }
}
