























import { Component, Vue, VModel } from 'vue-property-decorator';
import { icons } from '@/icons';

@Component({})
export default class IconSelector extends Vue {
    @VModel({ type: String }) public content!: any;

    private menuOpened: boolean = false;

    get iconSymbolList() {
        return icons.map((icon: string) => ({
            icon,
            name: 'asvg:' + icon,
            selected: this.content === icon,
        }));
    }

    private openMenu() {
        this.menuOpened = true;
    }

    private selectIcon(icon: string) {
        this.content = icon;
        this.menuOpened = false;
    }
}
