





































































import { Component, Prop, Vue, Watch, VModel } from 'vue-property-decorator';
import IconSelector from './IconSelector.vue';
import IndicatorValueMapper from './IndicatorValueMapper.vue';
import DashboardEditorComponentBase from './DashboardEditorComponentBase';

@Component({
    components: { IndicatorValueMapper, IconSelector },
})
export default class DashboardEditorComponentCounter extends DashboardEditorComponentBase {
    public created() {
        this.$store.dispatch('ensureIndicators');
    }

    public onUpdateInformationFromIndicator(indicator: any, defaults: any) {
        const ind = this.getIndicator(indicator.context, indicator.name);
        if (ind.fmt === 'counter_by') {
            this.options_icon_mapping = true;
        } else {
            this.options_icon_mapping = false;
        }
    }

    public filterIndicatorOptions(indicator: any) {
        return indicator.fmt === 'counter_by' || indicator.fmt === 'counter';
    }

}
