import { Component, Prop, Vue, Watch, VModel } from 'vue-property-decorator';

export default class DashboardEditorComponentBase extends Vue {
    @VModel({ type: Object }) public content!: object;

    public getdefault(obj: any, key: any, value: any) {
        if (obj[key] === undefined) {
            this.$set(obj, key, value);
        }
        return obj[key];
    }

    public getIndicator(context: string, name: string) {
        for (const iterator of this.$store.state.indicators) {
            if (context !== iterator.context) {
                continue;
            }
            if (name !== iterator.key) {
                continue;
            }
            return iterator;
        }
        return;
    }

    public getIndicatorHelp(context: string, name: string) {
        const indicatorInfos = this.getIndicator(context, name);
        if (!indicatorInfos) {
            return '';
        }
        return indicatorInfos.description;
    }

    get selectIndicatorOptions() {
        return this.$store.state.indicators
            .filter((entry: any) => this.filterIndicatorOptions(entry))
            .map((entry: any) => {
                return {
                    label: entry.title,
                    value: {
                        context: entry.context,
                        name: entry.key,
                        id: `{${entry.context}.id}`,

                    },
                    html: true,
                };
            });
    }

    public filterIndicatorOptions(indicator: any) {
        return true;
    }

    public onUpdateInformationFromIndicator(indicator: any, defaults: any) {
        return;
    }

    @Watch('indicator')
    private updateInformationFromIndicator(indicator: any) {
        const indicatorInfos = this.getIndicator(indicator.context, indicator.name);
        const defaults = indicatorInfos.defaults;
        this.options_title_text = defaults.title || '';
        this.options_subtitle_text = defaults.subtitle || '';
        this.options_unit_text = defaults.unit || '';
        this.options_icon_symbol = defaults.icon || 'qai-good';
        this.options_mapping_icon = defaults.icon_mapping;
        this.options_mapping_text = defaults.text_mapping;
        this.options_mapping_color = defaults.color_mapping;
        this.options_value_mapping = defaults.use_value_mapping;
        this.options_icon_mapping = defaults.use_icon_mapping;
        this.onUpdateInformationFromIndicator(indicator, defaults);
    }

    get indicator() {
        const content: any = this.content;
        return content.indicator;
    }

    set indicator(value: any) {
        this.$set(this.content, 'indicator', value);
    }

    get options() {
        const content: any = this.content;
        return content.options;
    }

    set options(value: any) {
        this.$set(this.content, 'options', value);
    }

    get options_title_text() {
        const obj = this.getdefault(this.options, 'title', {});
        return obj.text || '';
    }

    set options_title_text(value: any) {
        const obj = this.getdefault(this.options, 'title', {});
        this.$set(obj, 'text', value);
        this.$set(obj, 'disabled', value.length === 0);
    }

    get options_subtitle_text() {
        const obj = this.getdefault(this.options, 'subtitle', {});
        return obj.text || '';
    }

    set options_subtitle_text(value: any) {
        const obj = this.getdefault(this.options, 'subtitle', {});
        this.$set(obj, 'text', value);
        this.$set(obj, 'disabled', value.length === 0);
    }

    get options_unit_text() {
        const obj = this.getdefault(this.options, 'unit', {});
        return obj.text || '';
    }

    set options_unit_text(value: any) {
        const obj = this.getdefault(this.options, 'unit', {});
        this.$set(obj, 'text', value);
        this.$set(obj, 'disabled', value.length === 0);
    }

    get options_value_mapping() {
        const obj = this.getdefault(this.options, 'value', {});
        return obj['use-mapping'] || false;
    }

    set options_value_mapping(value: any) {
        const obj = this.getdefault(this.options, 'value', {});
        this.$set(obj, 'use-mapping', value);
    }

    get options_value_disabled() {
        const obj = this.getdefault(this.options, 'value', {});
        return obj['disabled'] || false;
    }

    set options_value_disabled(value: any) {
        const obj = this.getdefault(this.options, 'value', {});
        this.$set(obj, 'disabled', value);
    }

    get options_icon_mapping() {
        const obj = this.getdefault(this.options, 'icon', {});
        return obj['use-mapping'] || false;
    }

    set options_icon_mapping(value: any) {
        const obj = this.getdefault(this.options, 'icon', {});
        this.$set(obj, 'use-mapping', value);
    }

    get options_icon_disabled() {
        const obj = this.getdefault(this.options, 'icon', {});
        return obj['disabled'] || false;
    }

    set options_icon_disabled(value: any) {
        const obj = this.getdefault(this.options, 'icon', {});
        this.$set(obj, 'disabled', value);
    }

    get options_icon_symbol() {
        const obj = this.getdefault(this.options, 'icon', {});
        return obj['symbol'] || 'qai-good';
    }

    set options_icon_symbol(value: any) {
        const obj = this.getdefault(this.options, 'icon', {});
        this.$set(obj, 'symbol', value);
    }

    get options_mapping_text() {
        const obj = this.getdefault(this.options, 'mapping', {});
        return obj['text'] || {};
    }

    set options_mapping_text(value: any) {
        const obj = this.getdefault(this.options, 'mapping', {});
        this.$set(obj, 'text', value);
    }

    get options_mapping_color() {
        const obj = this.getdefault(this.options, 'mapping', {});
        return obj['color'] || {};
    }

    set options_mapping_color(value: any) {
        const obj = this.getdefault(this.options, 'mapping', {});
        this.$set(obj, 'color', value);
    }

    get options_mapping_icon() {
        const obj = this.getdefault(this.options, 'mapping', {});
        return obj['icon'] || {};
    }

    set options_mapping_icon(value: any) {
        const obj = this.getdefault(this.options, 'mapping', {});
        this.$set(obj, 'icon', value);
    }
}
