











import { Component, Prop, Vue, Watch, VModel } from 'vue-property-decorator';

@Component({})
export default class DashboardEditorComponentSection extends Vue {
    @VModel({ type: Object }) public content!: object;

    get options() {
        const content: any = this.content;
        return content.options;
    }

    get options_title_text() {
        const title = this.options.title || {};
        return title.text || '';
    }

    set options_title_text(value: any) {
        const obj = this.options.title || {};
        obj.text = value;
        obj.enabled = value.length > 0;
        this.$set(this.options, 'title', obj);
    }
}
