















































































































import { Component, Prop, Vue, Watch, Mixins } from 'vue-property-decorator';
import LineChart from '@/components/LineChart.vue';
import BarChart from '@/components/BarChart.vue';
import date from 'quasar/src/utils/date.js';;
import pupa from 'pupa';


@Component({
  components: {
    LineChart,
    BarChart,
  },
})
export default class DashboardComponentCard extends Vue {
  @Prop() public component!: object;
  @Prop() public variables!: object;
  @Prop() public options!: any;
  @Prop() public data!: any;
  @Prop() public error!: any;
  @Prop() public embed!: boolean;

  private chartData: {
      labels: string[],
      datasets: any,
  } = {
      labels: [],
      datasets: null,
  };

  get showAreaPrevNext() {
    const variables: any = this.variables;
    const component: any = this.component;
    return (
      variables.dashboard_type === 'building' &&
      component.indicator &&
      component.indicator.context === 'area');
  }

  get chartOptions() {
    return {
      animation: false,
      tooltips: {
        callbacks: {
          mode: 'index',
          intersect: true,
          backgroundColor: '#ffffff',
          titleFontColor: '#637d90',
          bodyFontColor: '#637d90',
          displayColors: false,
          borderColor: '#637d9044',
          borderWidth: 1,
          label: (tooltipItem: any, data: any) => {
            // TODO
            return tooltipItem.yLabel;
          },
        },
      },
      scales: {
        yAxes: [{
          display: this.options.graph.type === 'line',
          gridLines: {
            borderDash: [2, 2],
            color: '#87c7c844',
          },
          scaleLabel: {
            display: false,
            labelString: 'Value',
          },
          ticks: {
            fontSize: 8,
            fontColor: '#637d90',
            beginAtZero: true,
            precision: 1,
          },
        }],
        xAxes: [{
          gridLines: {
            display: false,
          },
          ticks: {
            autoSkip: true,
            autoSkipPadding: 10,
            padding: 10,
            fontSize: 10,
            fontColor: '#637d90',
          },
        }],
      },
      annotation: {
        annotations: [],
      },
  };
}

  private mounted() {
    this.refreshChart();
  }

  private renderTemplate(text: any) {
    return pupa(text, this.variables);
  }

  private getTimeDiff(dt: string) {
    const d1 = new Date();
    const d2 = Date.parse(dt);
    const diff = date.getDateDiff(d1, d2, 'minutes');
    return diff;
  }

  private isOffline(dt?: string) {
    if (dt === undefined || dt === null) {
      return true;
    }
    const timediff = this.getTimeDiff(dt);
    if (timediff === -1) {
      return true;
    }
    return timediff >= 120;
  }


  get status() {
    if (this.data === null) {
      if (this.error !== null) {
        return 'error';
      }
      return 'loading';
    } else {
      if (!this.data.values || this.data.values.length === 0) {
        return 'nodata';
      }
      if (this.isOffline(this.data.last.time)) {
        return 'nodata';
      }
    }
    return 'ok';
  }

  get cssComponentStatus() {
    return 'dc-component-status-' + this.status;
  }

  get errorMessage() {
    const component: any = this.component;
    const context = component.indicator.context;
    const variables: any = this.variables;
    if (context === 'building' && !variables.building_id) {
      return 'Aucun bâtiment sélectionné';
    } else if (context === 'area' && !variables.area_id) {
      return 'Aucune pièce sélectionnée';
    }
    return 'Accès refusé';
  }

  get lastValue() {
    if (this.data && this.data.last) {
      return this.data.last.value;
    }
    return null;
  }

  get lastValueNumber() {
    if (Array.isArray(this.lastValue)) {
      return this.lastValue[0];
    } else {
      return this.lastValue;
    }
  }

  get lastValueMapping() {
    if (Array.isArray(this.lastValue)) {
      return this.lastValue[1];
    } else {
      return this.lastValue;
    }
  }

  get lastValueText() {
    let value = this.lastValueNumber;
    const valueMapping = this.lastValueMapping;

    if (value === null) {
      return '-';
    }
    if (this.options.value['use-mapping']) {
      value = this.options.mapping.text[valueMapping];
    }
    value = ('' + value);
    if (this.options.value['uppercase']) {
      value = value.toUpperCase();
    }
    return value;
  }

  get cssComponentColor() {
    const valueMapping = this.lastValueMapping;
    if (valueMapping === null || valueMapping === undefined) {
      return '#8CA0B3';
    }
    return this.options.mapping.color[valueMapping];
  }

  @Watch('data')
  private refreshChart() {
    if (!(this.data && this.data.values)) {
      return;
    }

    let fmtLabel = 'DD/MM - H[h]';
    if (this.options.duration === '24h') {
      fmtLabel = 'H:00';
    }

    const graphValues = [...this.data['values'].map((value) => {
      if (Array.isArray(value)) {
        return value[0];
      } else {
        return value;
      }
    })].reverse();
    const graphTime = [...this.data['time']].reverse();

    if (this.options.graph.type === 'line') {
      // linechart
      this.chartData = {
        datasets: [
          {
            borderColor: '#87c7c8',
            borderWidth: 3,
            data: graphValues,
            label: this.component['name'],
            pointBorderWidth: 0,
            pointRadius: 0,
            backgroundColor: '#D5EDF36D',
          },
        ],
        labels: this.formatLabels(
          fmtLabel, graphTime,
        ),
      };
    } else {
      // barchart
      const graphMappingValues = [...this.data['values'].map((value) => {
        if (Array.isArray(value)) {
          return value[1];
        } else {
          return value;
        }
      })].reverse();
      this.chartData = {
        datasets: [
          {
            borderWidth: 0,
            data: graphValues.map((value: any) => {
              if (value === undefined) {
                return 1;
              }
              return value + 1;
            }),
            label: this.component['name'],
            pointBorderWidth: 0,
            pointRadius: 0,
            backgroundColor: graphMappingValues.map((value: any) => {
              if (value === undefined) {
                return '#66666609';
              }
              return this.options.mapping.color[value];
            }),
          },
        ],
        labels: this.formatLabels(
          fmtLabel, graphTime,
        ),
      };
    }
  }

  private formatLabels(fmt: string, data: string[]): string[] {
    return data.map((entry) => {
      if (fmt === null) {
        return entry;
      }
      return date.formatDate(entry, fmt);
    });
  }

  private gotoPreviousArea() {
    this.$emit('template-command', 'AREA:PREV');
  }

  private gotoNextArea() {
    this.$emit('template-command', 'AREA:NEXT');
  }

}
