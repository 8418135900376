











import { Component, Prop, Vue, Watch, Mixins } from 'vue-property-decorator';
import pupa from 'pupa';

@Component({})
export default class DashboardComponentSection extends Vue {
  @Prop() public component!: object;
  @Prop() public variables!: object;
  @Prop() public options!: any;
  @Prop() public data!: any;

  private renderTemplate(text: any) {
    return pupa(text, this.variables);
  }
}
