




























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import pupa from 'pupa';


@Component({})
export default class DashboardComponentTip extends Vue {
  @Prop() public component!: object;
  @Prop() public variables!: object;
  @Prop() public options!: any;
  @Prop() public data!: any;
  @Prop() public error!: any;
  @Prop() public embed!: boolean;

  private renderTemplate(text: any) {
    return pupa(text, this.variables);
  }

  get status() {
    if (this.data === null) {
      if (this.error !== null) {
        return 'error';
      }
      return 'loading';
    } else {
      if (!this.data.conseil || this.data.conseil.length === 0) {
        return 'nodata';
      }
    }
    return 'ok';
  }

  get cssComponentStatus() {
    return 'dc-component-status-' + this.status;
  }

  get cssComponentColor() {
    return 'inherit';
  }

}
