import { render, staticRenderFns } from "./DashboardEditorComponentCounter.vue?vue&type=template&id=773a4581&scoped=true&"
import script from "./DashboardEditorComponentCounter.vue?vue&type=script&lang=ts&"
export * from "./DashboardEditorComponentCounter.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "773a4581",
  null
  
)

export default component.exports
import QSelect from 'quasar/src/components/select/QSelect.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSelect,QInput,QToggle});
