















































































import { Component, Prop, Vue, Watch, VModel } from 'vue-property-decorator';
import IndicatorValueMapper from './IndicatorValueMapper.vue';
import DashboardEditorComponentBase from './DashboardEditorComponentBase';

@Component({
    components: {
        IndicatorValueMapper,
    },
})
export default class DashboardEditorComponentMap extends DashboardEditorComponentBase {
    public created() {
        this.$store.dispatch('ensureIndicators');
    }

    public filterIndicatorOptions(indicator: any) {
        return indicator.fmt === 'value' && indicator.context === 'area';
    }

    public onUpdateInformationFromIndicator(indicator: any, defaults: any) {
        this.options_subtitle_text = '{building.name}';
        this.options_mapping_color = defaults.color_mapping;
        this.options_value_mapping = defaults.use_value_mapping;
        this.options_slider_interval = 10;
    }

    get options_slider_interval() {
        const obj = this.getdefault(this.options, 'slider', {});
        return obj.interval || 0;
    }

    set options_slider_interval(value: any) {
        const obj = this.getdefault(this.options, 'slider', {});
        this.$set(obj, 'interval', value);
    }

}
