















































import { Component, Prop, Vue, Watch, VModel } from 'vue-property-decorator';
import QColor from 'quasar/src/components/color/QColor.js';;
import IconSelector from './IconSelector.vue';

@Component({
    components: {
        QColor,
        IconSelector,
    },
})
export default class IndicatorValueMapper extends Vue {
    @VModel() public content!: any;
    @Prop() public mapperType!: string;
}
