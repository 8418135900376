












import { Component, Prop, Vue, Watch, Mixins } from 'vue-property-decorator';
// @ts-ignore
import InternalChartBar from '@/components/InternalChartBar';
import { merge } from 'typescript-object-utils';

@Component({
  components: {
    InternalChartBar,
  },
})
export default class BarChart extends Vue {
  @Prop() public data!: any;
  @Prop() public options!: any;
  @Prop() public chartHeight!: number;
  @Prop() public chartWidth!: number;

  public loaded: boolean = false;

  public chartOptions(): object {
    const baseoptions = {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        mode: 'index',
        intersect: false,
        backgroundColor: '#ffffff',
        titleFontColor: '#637d90',
        bodyFontColor: '#637d90',
        displayColors: false,
        borderColor: '#637d9044',
        borderWidth: 1,
      },
      hover: {
        mode: 'nearest',
        intersect: true,
      },
      legend: {
          display: false,
      },
      scales: {
        yAxes: [{
          display: true,
          gridLines: {
            borderDash: [2, 2],
            color: '#87c7c844',
          },
          scaleLabel: {
            display: false,
            labelString: 'Value',
          },
          ticks: {
            fontSize: 10,
            fontColor: '#637d90',
          },
        }],
        xAxes: [{
          gridLines: {
            color: '#87c7c844',
          },
          ticks: {
            autoSkip: true,
            autoSkipPadding: 2,
            maxRotation: 0,
            padding: 10,
            fontSize: 10,
            fontColor: '#637d90',
          },
        }],
      },
    };
    return merge(baseoptions, this.options);
  }

  public chartData(): object {
    return this.data;
  }

  @Watch('data')
  public onDataChanged() {
    this.loaded = true;
  }

  public mounted() {
    if (this.data.datasets) {
      this.loaded = true;
    }
  }
}
