



















































































import { Component, Prop, Vue, Watch, VModel } from 'vue-property-decorator';
import DashboardEditorComponentSection from './DashboardEditorComponentSection.vue';
import DashboardEditorComponentCard from './DashboardEditorComponentCard.vue';
import DashboardEditorComponentMap from './DashboardEditorComponentMap.vue';
import DashboardEditorComponentCounter from './DashboardEditorComponentCounter.vue';
import DashboardEditorComponentTip from './DashboardEditorComponentTip.vue';
import DashboardRenderer from './DashboardRenderer.vue';
import DashboardComponent from './DashboardComponent.vue';
import cloneDeep from 'lodash/cloneDeep';

@Component({
    components: {
        DashboardEditorComponentSection,
        DashboardEditorComponentCard,
        DashboardEditorComponentMap,
        DashboardEditorComponentCounter,
        DashboardEditorComponentTip,
        DashboardRenderer,
        DashboardComponent,
    },
})
export default class DashboardEditorComponent extends Vue {
    @VModel({ type: Object }) public content!: any;
    @Prop() public variables!: any;
    private section: any = {label: 'Indicateur', value: 'card'};
    private openEditor: boolean = false;

    private setComponent() {
        const content: any = this.content;
        this.$set(this.content, 'type', this.section.value);
        this.$set(this.content, 'options', {});
    }

    private getComponentTemplate() {
        return cloneDeep(this.content);
    }

    private deleteComponent() {
        this.$q.dialog({
            title: 'Supprimer le composant',
            message: 'Voulez-vous supprimer ce composant ?',
            ok: { label: 'Oui' },
            cancel: { label: 'Non', flat: true },
        }).onOk(() => {
            this.$emit('delete-component');
        });
    }
}
