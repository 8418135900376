




























































































































import { Component, Prop, Vue, Watch, VModel } from 'vue-property-decorator';
import IconSelector from './IconSelector.vue';
import IndicatorValueMapper from './IndicatorValueMapper.vue';
import DashboardEditorComponentBase from './DashboardEditorComponentBase';

@Component({
    components: {
        IconSelector,
        IndicatorValueMapper,
    },
})
export default class DashboardEditorComponentCard extends DashboardEditorComponentBase {
    public created() {
        this.$store.dispatch('ensureIndicators');
    }

    public filterIndicatorOptions(indicator: any) {
        return indicator.fmt === 'value';
    }

    public onUpdateInformationFromIndicator(indicator: any, defaults: any) {
        this.options_graph_type = null;
    }

    get options_graph_type() {
        const obj = this.getdefault(this.options, 'graph', {});
        return obj.type || null;
    }

    set options_graph_type(value: any) {
        const obj = this.getdefault(this.options, 'graph', {});
        this.$set(obj, 'type', value);
        this.$set(obj, 'disabled', value === null);
    }

}
