import { render, staticRenderFns } from "./DashboardEditorComponentCard.vue?vue&type=template&id=9963fc96&"
import script from "./DashboardEditorComponentCard.vue?vue&type=script&lang=ts&"
export * from "./DashboardEditorComponentCard.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QSelect from 'quasar/src/components/select/QSelect.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSelect,QInput,QBtnToggle,QToggle});
